import React from 'react';
import { Button, Card, Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
//import './Mapa.css';
import GoogleMapReact from 'google-map-react';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { OverlayView, GoogleMap, LoadScript, useJsApiLoader, Data } from '@react-google-maps/api';
import Paper from '@material-ui/core/Paper';
import { Marker } from '@react-google-maps/api';

/* 
documentacion 
https://react-google-maps-api-docs.netlify.app/#section-introduction

*/


const containerStyle = {
    width: '100%',
    height: '400px',
    borderRadius: "6px",
    flexWrap: "wrap",
    display: "flex",


};

const center = {
    lat: -3.745,
    lng: -38.523
};
const Mapa = (props) => {

    console.log("MAP key", process.env.REACT_APP_MAPS);

    const { info } = props;
    const defaultProps = {
        center: {
            lat: parseFloat(info.lat),
            lng: parseFloat(info.long)
        },
        zoom: 15
    };

    const onLoad = marker => {
        //console.log( 'marker: ', marker );
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_MAPS
    });

    return isLoaded ? (



        <GoogleMap
            mapContainerStyle={containerStyle}
            center={{ lat: parseFloat(info.lat), lng: parseFloat(info.long) }}
            zoom={19}
        //options={ { MapTypeId: "satellite" } }
        >
            <Marker
                onLoad={onLoad}
                position={{ lat: parseFloat(info.lat), lng: parseFloat(info.long) }}
                label={info.niv}
                visible={true}
            //onMouseOver={ () => console.log( 'Mouse over Marker' ) }
            />


        </GoogleMap>

    ) : <></>;







};

export default Mapa;