import React, { useEffect, useState } from 'react';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, onSnapshot } from "firebase/firestore";

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import Card from './Card';
import Dato from './Dato';
import Mapa from './Mapa';
import "./Mapa.css";
const useStyles = makeStyles( {
    table: {
        minWidth: 650,
    },
} );


const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};


const firebaseApp = initializeApp( firebaseConfig );
const db = getFirestore( firebaseApp );


const Lecturas = () => {
    const classes = useStyles();
    const [ page, setPage ] = React.useState( 0 );
    const [ rowsPerPage, setRowsPerPage ] = React.useState( 5 );

    const handleChangePage = ( event, newPage ) => {
        setPage( newPage );
    };

    const handleChangeRowsPerPage = ( event ) => {
        setRowsPerPage( +event.target.value );
        setPage( 0 );
    };


    const [ data, setData ] = useState( [] );

    useEffect( () => {

        const unsub = onSnapshot( collection( db, "repuve" ), { includeMetadataChanges: true }, ( querySnapshot ) => {

            let temp = [];
            querySnapshot.forEach( ( doc ) => {
                const date = new Date( parseInt( doc.id ) );
                temp = [ ...temp, { id: doc.id, date, ...doc.data() } ];
                setData( temp.reverse() );



            }
            );


        } );

        return () => unsub();

    }, [] );







    return (
        <React.Fragment >
            <Grid
                container

                style={ {
                    marginBottom: "20px",
                    marginTop: "20px"
                } }
                direction="row"
                justifyContent="space-evenly"
                alignItems="flex-end"
            >

                <Grid item xs >
                    <Paper className={ classes.paper } style={ { paddingTop: "10px" } }>
                        <img src="logo.jpg" alt="logo" style={ { width: "30%", marginTop: "5px" } } />
                    </Paper>
                </Grid>

            </Grid>
            <Grid
                container spacing={ 3 }
                style={ { marginBottom: "20px" } }
                direction="row"
                justifyContent="space-evenly"
                alignItems="flex-end"
                flexWrap="wrap"
            >

                <Grid item xs >
                    { data[ 0 ] ? <Paper className={ classes.paper } ><Card data={ data[ 0 ] } /></Paper> : "" }
                </Grid>
                <Grid item xs>
                    { data[ 0 ] ? <Paper className="papelmapa"><Mapa info={ { lat: data[ 0 ].lat, long: data[ 0 ].lng, niv: data[ 0 ] ? data[ 0 ].niv : "" } } /></Paper> : "" }
                </Grid>

            </Grid>

            <TableContainer component={ Paper } style={ { marginBottom: "5%" } }>
                <Table stickyHeader className={ classes.table } size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Fecha</TableCell>
                            <TableCell align="right">NIV</TableCell>
                            <TableCell align="right">Folio</TableCell>
                            <TableCell align="right">Constancia</TableCell>
                            <TableCell align="right">Cliente</TableCell>
                            <TableCell align="right">Terminal</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { data.slice( page * rowsPerPage, page * rowsPerPage + rowsPerPage ).map( ( row ) => (
                            <TableRow tabIndex={ -1 } key={ row.id }>
                                <TableCell component="th" scope="row">
                                    { row.date.toLocaleString() }
                                </TableCell>
                                <TableCell align="right">{ row.niv }</TableCell>
                                <TableCell align="right">{ row.folio }</TableCell>
                                <TableCell align="right">{ row.constancia }</TableCell>
                                <TableCell align="right">{ row.cliente }</TableCell>
                                <TableCell align="right">{ row.terminal }</TableCell>
                            </TableRow>
                        ) ) }
                    </TableBody>
                </Table>  <TablePagination
                    rowsPerPageOptions={ [ 5, 10, 100 ] }
                    component="div"
                    count={ data.length }
                    rowsPerPage={ rowsPerPage }
                    labelRowsPerPage="Filas por página"
                    page={ page }
                    onPageChange={ handleChangePage }
                    onRowsPerPageChange={ handleChangeRowsPerPage }

                />
            </TableContainer>

        </React.Fragment>
    );
};

export default Lecturas;