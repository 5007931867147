import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Lecturas from "./components/Lecturas";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <CssBaseline />
        <Container maxWidth="md">
          <Lecturas />
        </Container>
      </header>
    </div>
  );
}

export default App;
