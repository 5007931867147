import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles( {
    root: {
        minWidth: 275,
        height: 400,
        flexShrink: 0

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    paper: {
        fontSize: "1.4rem",
        flexShrink: 0
    }

} );

export default function SimpleCard ( { data } ) {
    const classes = useStyles();

    return (
        <Card className={ classes.root } >
            <Grid container spacing={ 3 }>
                <Grid item xs={ 6 } style={ { marginTop: "10%" } }>
                    <Paper className={ classes.paper }>Fecha</Paper>
                </Grid>
                <Grid item xs={ 6 }>
                    <Paper className={ classes.paper } style={ { marginTop: "10%" } }>{ data ? data.date.toLocaleString() : '' }</Paper>
                </Grid>

            </Grid>
            <Grid container spacing={ 3 }>
                <Grid item xs={ 3 } style={ { marginTop: "3%" } }>
                    <Paper className={ classes.paper }>NIV</Paper>
                </Grid>
                <Grid item xs={ 9 }>
                    <Paper className={ classes.paper } style={ { marginTop: "3%" } }>{ data ? data.niv : '' }</Paper>
                </Grid>

            </Grid>
            <Grid container spacing={ 3 }>
                <Grid item xs={ 6 } style={ { marginTop: "3%" } }>
                    <Paper className={ classes.paper }>Folio</Paper>
                </Grid>
                <Grid item xs={ 6 }>
                    <Paper className={ classes.paper } style={ { marginTop: "3%" } }>{ data ? data.folio : '' }</Paper>
                </Grid>

            </Grid>
            <Grid container spacing={ 3 }>
                <Grid item xs={ 4 }>
                    <Paper className={ classes.paper } style={ { marginTop: "3%" } } >Constancia</Paper>
                </Grid>
                <Grid item xs={ 8 }>
                    <Paper className={ classes.paper } style={ { marginTop: "3%" } }>{ data ? data.constancia : '' }</Paper>
                </Grid>

            </Grid>
            <Grid container spacing={ 3 }>
                <Grid item xs={ 4 }>
                    <Paper className={ classes.paper } style={ { marginTop: "3%" } }>Cliente</Paper>
                </Grid>
                <Grid item xs={ 8 }>
                    <Paper className={ classes.paper } style={ { marginTop: "3%" } }>{ data ? data.cliente : '' }</Paper>
                </Grid>

            </Grid>
            <Grid container spacing={ 3 }>
                <Grid item xs={ 4 }>
                    <Paper className={ classes.paper } style={ { marginTop: "3%" } }>Terminal</Paper>
                </Grid>
                <Grid item xs={ 8 }>
                    <Paper className={ classes.paper } style={ { marginTop: "3%" } }>{ data ? data.terminal : '' }</Paper>
                </Grid>

            </Grid>

        </Card >
    );
}
